<template>
  <in-layout>
    <template slot="list">
      <v-container grid-list-md class="px-4">

        <v-layout column class="ma-2">
          <div class="mb-4">
            <router-link style="cursor: pointer" tag="span" :to="{ name: 'Configurações'}">
              <v-icon size="18">mdi-chevron-left</v-icon>
              Voltar para configurações
            </router-link>
          </div>

          <h1>Empresas cadastradas</h1>

          <v-layout row align-center justify-space-between>
            <v-flex xs4>
              <v-text-field
                v-model="search"
                name="search"
                label="Pesquisar empresa"
                prepend-inner-icon="mdi-magnify"
                clearable
                @click:clear="typing()"
                @input="typing()"
              />
            </v-flex>

            <v-flex xs3>
              <v-layout justify-end align-center>
                Exibindo {{ showingStart }} - {{ showingEnd }} de {{ totalItems }}
                <v-btn
                  icon
                  @click="pageNumber-=1"
                  :disabled="pageNumber <= 1"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="pageNumber+=1"
                  :disabled="pageNumber >= totalPages"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-layout>

        <v-data-table
          :headers="headers"
          :items="companies"
          :loading="loading"
          hide-actions
          no-data-text="Nenhuma empresa encontrada"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.id }}</td>
            <td>{{ formatCpfCnpj(props.item.cpf_cnpj) }}</td>
            <td>{{ props.item.name }}</td>
            <td class="text-xs-right">
              <v-btn small icon flat>
                <v-icon @click="viewCompany(props.item.id)">mdi-eye</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-container>
    </template>
  </in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';
import PersonService from '@/services/person-service';
import formatCpfCnpj from '@/utils/formatCpfCnpj';

export default {
  name: 'CompaniesList',
  components: {
    InLayout,
    PersonService,
  },
  mounted() {
    this.getCompanies(1);
  },
  data() {
    return {
      loading: false,
      companies: [],
      formatCpfCnpj,
      pageNumber: 1,
      itemsPerPage: 20,
      totalPages: 0,
      search: '',
      totalItems: 0,
      interval: null,
      headers: [
        {
          text: 'ID', value: 'id', sortable: false, width: 150,
        },
        {
          text: 'CPF/CNPJ', value: 'CPF_CNPJ', align: 'left', sortable: false, width: 350,
        },
        {
          text: 'Nome', value: 'Nome', align: 'left', sortable: false, width: 400,
        },
        {
          text: 'Ações', value: 'actions', align: 'right', sortable: false, width: 200,
        },
      ],
    };
  },
  methods: {
    getCompanies(pageNumber) {
      this.pageNumber = pageNumber || this.pageNumber;
      this.loading = true;
      PersonService
        .getCompanies({
          page: this.pageNumber,
          limit: this.itemsPerPage,
          q: this.search || '',
        })
        .then((response) => {
          this.companies = response.data.peoples;
          this.totalItems = response.data.total_items;
          this.totalPages = response.data.total_pages;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    viewCompany(companyId) {
      this.$router.push({ name: 'Dados da Empresa', params: { id: companyId } });
    },
    typing() {
      clearInterval(this.interval);
      this.interval = setTimeout(() => {
        this.getCompanies(1);
      }, 500);
    },
  },
  computed: {
    showingStart() {
      return (this.pageNumber - 1 === 0) ? 1 : (this.pageNumber - 1) * this.itemsPerPage;
    },
    showingEnd() {
      if (this.totalPages === this.pageNumber) {
        return this.totalItems;
      }
      return (this.pageNumber * this.itemsPerPage);
    },
  },
  watch: {
    pageNumber() {
      this.getCompanies(this.pageNumber);
    },
  },
};
</script>
